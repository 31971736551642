@import "./icons.scss";
@import "./tailwind.scss";
@import "../../../node_modules/prismjs/themes/prism-okaidia.css"; 
@import "../../../node_modules/lightbox.js-react/dist/index.css"; 

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
  }
  
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  
  